define("discourse/plugins/discourse-data-explorer/discourse/components/param-input-form", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/string", "@ember/utils", "discourse/components/conditional-loading-spinner", "discourse/components/form", "discourse/models/category", "I18n", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/boolean-three", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/category-id-input", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/group-input", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/user-id-input", "discourse/plugins/discourse-data-explorer/discourse/components/param-input/user-list-input", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _object, _service, _string, _utils, _conditionalLoadingSpinner, _form, _category, _I18n, _booleanThree, _categoryIdInput, _groupInput, _userIdInput, _userListInput, _component2, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ParamValidationError = _exports.ERRORS = void 0;
  class ParamValidationError extends Error {}
  _exports.ParamValidationError = ParamValidationError;
  const layoutMap = {
    int: "int",
    bigint: "string",
    boolean: "boolean",
    string: "string",
    time: "time",
    date: "date",
    datetime: "datetime",
    double: "string",
    user_id: "user_id",
    post_id: "string",
    topic_id: "generic",
    category_id: "category_id",
    group_id: "group_list",
    badge_id: "generic",
    int_list: "generic",
    string_list: "generic",
    user_list: "user_list",
    group_list: "group_list"
  };
  const ERRORS = _exports.ERRORS = {
    REQUIRED: _I18n.default.t("form_kit.errors.required"),
    NOT_AN_INTEGER: _I18n.default.t("form_kit.errors.not_an_integer"),
    NOT_A_NUMBER: _I18n.default.t("form_kit.errors.not_a_number"),
    OVERFLOW_HIGH: _I18n.default.t("form_kit.errors.too_high", {
      count: 2147484647
    }),
    OVERFLOW_LOW: _I18n.default.t("form_kit.errors.too_low", {
      count: -2147484648
    }),
    INVALID: _I18n.default.t("explorer.form.errors.invalid"),
    NO_SUCH_CATEGORY: _I18n.default.t("explorer.form.errors.no_such_category"),
    NO_SUCH_GROUP: _I18n.default.t("explorer.form.errors.no_such_group"),
    INVALID_DATE: date1 => _I18n.default.t("explorer.form.errors.invalid_date", {
      date: date1
    }),
    INVALID_TIME: time1 => _I18n.default.t("explorer.form.errors.invalid_time", {
      time: time1
    })
  };
  function digitalizeCategoryId(value1) {
    value1 = String(value1 || "");
    const isPositiveInt1 = /^\d+$/.test(value1);
    if (!isPositiveInt1 && value1.trim()) {
      return _category.default.asyncFindBySlugPath((0, _string.dasherize)(value1)).then(res1 => res1.id).catch(err1 => {
        if (err1.jqXHR?.status === 404) {
          throw new ParamValidationError(`${ERRORS.NO_SUCH_CATEGORY}: ${value1}`);
        } else {
          throw new Error(err1.errorThrow || err1.message);
        }
      });
    }
    return value1;
  }
  function serializeValue(type1, value1) {
    switch (type1) {
      case "string":
      case "int":
        return value1 != null ? String(value1) : "";
      case "boolean":
        return String(value1);
      case "group_list":
      case "user_list":
        return value1?.join(",");
      case "group_id":
        return value1[0];
      case "datetime":
        return value1?.replaceAll("T", " ");
      default:
        return value1?.toString();
    }
  }
  function validationOf(info1) {
    switch (layoutMap[info1.type]) {
      case "boolean":
        return info1.nullable ? "required" : "";
      case "string":
      case "string_list":
      case "generic":
        return info1.nullable ? "" : "required:trim";
      default:
        return info1.nullable ? "" : "required";
    }
  }
  function componentOf(info) {
    let type = layoutMap[info.type] || "generic";
    if (info.nullable && type === "boolean") {
      type = "boolean_three";
    }
    switch (type) {
      case "int":
        return (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <@field.Input @type="number" name={{@info.identifier}} />
              
        */
        {
          "id": "DuY8uCXe",
          "block": "[[[1,\"\\n        \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"number\"]],null],[1,\"\\n      \"]],[\"@field\",\"@info\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      case "boolean":
        return (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          <@field.Checkbox name={{@info.identifier}} />
        */
        {
          "id": "EZWX6cT0",
          "block": "[[[8,[30,1,[\"Checkbox\"]],[[16,3,[30,2,[\"identifier\"]]]],null,null]],[\"@field\",\"@info\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      case "boolean_three":
        return _booleanThree.default;
      case "category_id":
        // TODO
        return _categoryIdInput.default;
      case "user_id":
        return _userIdInput.default;
      case "user_list":
        return _userListInput.default;
      case "group_list":
        return _groupInput.default;
      case "date":
        return (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <@field.Input @type="date" name={{@info.identifier}} />
              
        */
        {
          "id": "01Kg63JG",
          "block": "[[[1,\"\\n        \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"date\"]],null],[1,\"\\n      \"]],[\"@field\",\"@info\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      case "time":
        return (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <@field.Input @type="time" name={{@info.identifier}} />
              
        */
        {
          "id": "nVj6fQEw",
          "block": "[[[1,\"\\n        \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"time\"]],null],[1,\"\\n      \"]],[\"@field\",\"@info\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      case "datetime":
        return (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <@field.Input @type="datetime-local" name={{@info.identifier}} />
              
        */
        {
          "id": "HGhQ1u0N",
          "block": "[[[1,\"\\n        \"],[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],[[\"@type\"],[\"datetime-local\"]],null],[1,\"\\n      \"]],[\"@field\",\"@info\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
      case "bigint":
      case "string":
      default:
        return (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          <@field.Input name={{@info.identifier}} />
        */
        {
          "id": "RWJET0M1",
          "block": "[[[8,[30,1,[\"Input\"]],[[16,3,[30,2,[\"identifier\"]]]],null,null]],[\"@field\",\"@info\"],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined));
    }
  }
  class ParamInputForm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    data = {};
    paramInfo = [];
    infoOf = {};
    form = null;
    promiseNormalizations = [];
    formLoaded = (() => new Promise(res1 => {
      this.__form_load_callback = res1;
    }))();
    constructor() {
      super(...arguments);
      this.initializeParams();
      this.args.onRegisterApi?.({
        submit: this.submit,
        allNormalized: Promise.allSettled(this.promiseNormalizations)
      });
    }
    initializeParams() {
      this.args.paramInfo.forEach(info1 => {
        const identifier1 = info1.identifier;
        const pinfo1 = this.createParamInfo(info1);
        this.paramInfo.push(pinfo1);
        this.infoOf[identifier1] = info1;
        const normalized1 = this.getNormalizedValue(info1);
        if (normalized1 instanceof Promise) {
          this.handlePromiseNormalization(normalized1, pinfo1);
        } else {
          this.data[identifier1] = normalized1;
        }
      });
    }
    createParamInfo(info1) {
      return _object.default.create({
        ...info1,
        validation: validationOf(info1),
        validate: this.validatorOf(info1),
        component: componentOf(info1)
      });
    }
    async addError(identifier1, message1) {
      await this.formLoaded;
      this.form.addError(identifier1, {
        title: identifier1,
        message: message1
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "addError", [_object.action]))();
    normalizeValue(info1, value1) {
      switch (info1.type) {
        case "category_id":
          return digitalizeCategoryId(value1);
        case "boolean":
          if (value1 == null) {
            return info1.nullable ? "#null" : false;
          }
          return value1;
        case "group_id":
        case "group_list":
          const normalized1 = this.normalizeGroups(value1);
          if (normalized1.errorMsg) {
            this.addError(info1.identifier, normalized1.errorMsg);
          }
          return info1.type === "group_id" ? normalized1.value.slice(0, 1) : normalized1.value;
        case "user_list":
          if (Array.isArray(value1)) {
            return value1 || null;
          }
          return value1?.split(",") || null;
        case "user_id":
          if (Array.isArray(value1)) {
            return value1[0];
          }
          return value1;
        case "date":
          try {
            if (!value1) {
              return null;
            }
            return moment(new Date(value1).toISOString()).format("YYYY-MM-DD");
          } catch {
            this.addError(info1.identifier, ERRORS.INVALID_DATE(String(value1)));
            return null;
          }
        case "time":
          try {
            if (!value1) {
              return null;
            }
            return moment(new Date(`1970/01/01 ${value1}`).toISOString()).format("HH:mm");
          } catch {
            this.addError(info1.identifier, ERRORS.INVALID_TIME(String(value1)));
            return null;
          }
        case "datetime":
          try {
            if (!value1) {
              return null;
            }
            return moment(new Date(value1).toISOString()).format("YYYY-MM-DD HH:mm");
          } catch {
            this.addError(info1.identifier, ERRORS.INVALID_TIME(String(value1)));
            return null;
          }
        default:
          return value1;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "normalizeValue", [_object.action]))();
    getNormalizedValue(info1) {
      const initialValues1 = this.args.initialValues;
      const identifier1 = info1.identifier;
      return this.normalizeValue(info1, initialValues1 && identifier1 in initialValues1 ? initialValues1[identifier1] : info1.default);
    }
    handlePromiseNormalization(promise1, pinfo1) {
      this.promiseNormalizations.push(promise1);
      pinfo1.set("loading", true);
      this.data[pinfo1.identifier] = null;
      promise1.then(res1 => this.form.set(pinfo1.identifier, res1)).catch(err1 => this.addError(pinfo1.identifier, err1.message)).finally(() => pinfo1.set("loading", false));
    }
    normalizeGroups(values1) {
      values1 ||= [];
      if (typeof values1 === "string") {
        values1 = values1.split(",");
      }
      const GroupNames1 = new Set(this.site.get("groups").map(g1 => g1.name));
      const GroupNameOf1 = Object.fromEntries(this.site.get("groups").map(g1 => [g1.id, g1.name]));
      const valid_groups1 = [];
      const invalid_groups1 = [];
      for (const val1 of values1) {
        if (GroupNames1.has(val1)) {
          valid_groups1.push(val1);
        } else if (GroupNameOf1[Number(val1)]) {
          valid_groups1.push(GroupNameOf1[Number(val1)]);
        } else {
          invalid_groups1.push(String(val1));
        }
      }
      return {
        value: valid_groups1,
        errorMsg: invalid_groups1.length !== 0 ? `${ERRORS.NO_SUCH_GROUP}: ${invalid_groups1.join(", ")}` : null
      };
    }
    static #_4 = (() => dt7948.n(this.prototype, "normalizeGroups", [_object.action]))();
    getErrorFn(info1) {
      const isPositiveInt1 = value1 => /^\d+$/.test(value1);
      const VALIDATORS1 = {
        int: value1 => {
          if (value1 >= 2147483648) {
            return ERRORS.OVERFLOW_HIGH;
          }
          if (value1 <= -2147483649) {
            return ERRORS.OVERFLOW_LOW;
          }
          return null;
        },
        bigint: value1 => {
          if (isNaN(parseInt(value1, 10))) {
            return ERRORS.NOT_A_NUMBER;
          }
          return /^-?\d+$/.test(value1) ? null : ERRORS.NOT_AN_INTEGER;
        },
        boolean: value1 => {
          return /^Y|N|#null|true|false/.test(String(value1)) ? null : ERRORS.INVALID;
        },
        double: value1 => {
          if (isNaN(parseFloat(value1))) {
            if (/^(-?)Inf(inity)?$/i.test(value1) || /^(-?)NaN$/i.test(value1)) {
              return null;
            }
            return ERRORS.NOT_A_NUMBER;
          }
          return null;
        },
        int_list: value1 => {
          return value1.split(",").every(i1 => /^(-?\d+|null)$/.test(i1.trim())) ? null : ERRORS.INVALID;
        },
        post_id: value1 => {
          return isPositiveInt1(value1) || /\d+\/\d+(\?u=.*)?$/.test(value1) || /\/t\/[^/]+\/(\d+)(\?u=.*)?/.test(value1) ? null : ERRORS.INVALID;
        },
        topic_id: value1 => {
          return isPositiveInt1(value1) || /\/t\/[^/]+\/(\d+)/.test(value1) ? null : ERRORS.INVALID;
        },
        category_id: value1 => {
          return this.site.categoriesById.get(Number(value1)) ? null : ERRORS.NO_SUCH_CATEGORY;
        },
        group_list: value1 => {
          return this.normalizeGroups(value1).errorMsg;
        },
        group_id: value1 => {
          return this.normalizeGroups(value1).errorMsg;
        }
      };
      return VALIDATORS1[info1.type] ?? (() => null);
    }
    validatorOf(info1) {
      const getError1 = this.getErrorFn(info1);
      return (name1, value1, _ref) => {
        let {
          addError: addError1
        } = _ref;
        // skip require validation for we have used them in @validation
        if ((0, _utils.isEmpty)(value1) || value1 == null) {
          return;
        }
        const message1 = getError1(value1);
        if (message1 != null) {
          addError1(name1, {
            title: info1.identifier,
            message: message1
          });
        }
      };
    }
    async submit() {
      if (this.form == null) {
        throw "No form";
      }
      this.serializedData = null;
      await this.form.submit();
      if (this.serializedData == null) {
        throw new ParamValidationError("validation_failed");
      } else {
        return this.serializedData;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
    onRegisterApi(form1) {
      this.__form_load_callback();
      this.form = form1;
    }
    static #_6 = (() => dt7948.n(this.prototype, "onRegisterApi", [_object.action]))();
    onSubmit(data1) {
      const serializedData1 = {};
      for (const [id1, val1] of Object.entries(data1)) {
        serializedData1[id1] = serializeValue(this.infoOf[id1].type, val1) ?? undefined;
      }
      this.serializedData = serializedData1;
    }
    static #_7 = (() => dt7948.n(this.prototype, "onSubmit", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="query-params">
          <Form
            @data={{this.data}}
            @onRegisterApi={{this.onRegisterApi}}
            @onSubmit={{this.onSubmit}}
            class="params-form"
            as |form|
          >
            {{#each this.paramInfo as |info|}}
              <div class="param">
                <form.Field
                  @name={{info.identifier}}
                  @title={{info.identifier}}
                  @validation={{info.validation}}
                  @validate={{info.validate}}
                  as |field|
                >
                  <info.component @field={{field}} @info={{info}} />
                  <ConditionalLoadingSpinner
                    @condition={{info.loading}}
                    @size="small"
                  />
                </form.Field>
              </div>
            {{/each}}
          </Form>
        </div>
      
    */
    {
      "id": "K6kFJTOW",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"query-params\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"params-form\"]],[[\"@data\",\"@onRegisterApi\",\"@onSubmit\"],[[30,0,[\"data\"]],[30,0,[\"onRegisterApi\"]],[30,0,[\"onSubmit\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"paramInfo\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"param\"],[12],[1,\"\\n            \"],[8,[30,1,[\"Field\"]],null,[[\"@name\",\"@title\",\"@validation\",\"@validate\"],[[30,2,[\"identifier\"]],[30,2,[\"identifier\"]],[30,2,[\"validation\"]],[30,2,[\"validate\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[8,[30,2,[\"component\"]],null,[[\"@field\",\"@info\"],[[30,3],[30,2]]],null],[1,\"\\n              \"],[8,[32,1],null,[[\"@condition\",\"@size\"],[[30,2,[\"loading\"]],\"small\"]],null],[1,\"\\n            \"]],[3]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"]],[1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"form\",\"info\",\"field\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-data-explorer/discourse/components/param-input-form.js",
      "scope": () => [_form.default, _conditionalLoadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ParamInputForm;
});